import React from "react";
import SEO from "../../components/atoms/seo";

import Layout from "../../components/organisms/layout";
import Heading from "../../components/molecules/heading";
import Title from "../../components/atoms/title";
import BodyCopy from "../../components/atoms/body-copy";

const StyleguidePage = () => (
  <Layout>
    <SEO title="Styleguide" />
    <Heading title="Styleguide - Typography" weight="light" />
    <div className="p-styleguide p-styleguide__typography">
      <div className="p-styleguide p-styleguide__section">
        <Title
          type="h3"
          title="Fonts"
          align="left"
          color="dark"
          weight="bold"
        />
        <div className="p-styleguide p-styleguide__section--inner">
          <Title
            type="h5"
            title="Lora - Regular - 400"
            align="left"
            color="dark"
            weight="bold"
          />
          <div className="p-styleguide__indent">
            <Title
              type="h1"
              title="ABCDEFGHIJKLMNOPQRSTUVWXYZ"
              align="left"
              color="dark"
              weight="regular"
            />
            <Title
              type="h1"
              title="abcdefghijklmnopqrstuvwxyz"
              align="left"
              color="dark"
              weight="regular"
            />
            <Title
              type="h1"
              title="1234567890"
              align="left"
              color="dark"
              weight="regular"
            />
          </div>
        </div>

        <div className="p-styleguide p-styleguide__section--inner">
          <Title
            type="h5"
            title="Lora - Bold - 700"
            align="left"
            color="dark"
            weight="bold"
          />
          <div className="p-styleguide__indent">
            <Title
              type="h1"
              title="ABCDEFGHIJKLMNOPQRSTUVWXYZ"
              align="left"
              color="dark"
              weight="bold"
            />
            <Title
              type="h1"
              title="abcdefghijklmnopqrstuvwxyz"
              align="left"
              color="dark"
              weight="bold"
            />
            <Title
              type="h1"
              title="1234567890"
              align="left"
              color="dark"
              weight="bold"
            />
          </div>
        </div>

        <div className="p-styleguide p-styleguide__section--inner">
          <Title
            type="h5"
            title="Source Sans Pro - Light - 300"
            align="left"
            color="dark"
            weight="bold"
          />
          <div className="p-styleguide__indent">
            <Title
              type="h3"
              title="ABCDEFGHIJKLMNOPQRSTUVWXYZ"
              align="left"
              color="dark"
              weight="light"
            />
            <Title
              type="h3"
              title="abcdefghijklmnopqrstuvwxyz"
              align="left"
              color="dark"
              weight="light"
            />
            <Title
              type="h3"
              title="1234567890"
              align="left"
              color="dark"
              weight="light"
            />
          </div>

          <div className="p-styleguide p-styleguide__section--inner">
            <Title
              type="h5"
              title="Source Sans Pro - Regular - 400"
              align="left"
              color="dark"
              weight="bold"
            />
            <div className="p-styleguide__indent">
              <Title
                type="h3"
                title="ABCDEFGHIJKLMNOPQRSTUVWXYZ"
                align="left"
                color="dark"
                weight="regular"
              />
              <Title
                type="h3"
                title="abcdefghijklmnopqrstuvwxyz"
                align="left"
                color="dark"
                weight="regular"
              />
              <Title
                type="h3"
                title="1234567890"
                align="left"
                color="dark"
                weight="regular"
              />
            </div>
          </div>

          <div className="p-styleguide p-styleguide__section--inner">
            <Title
              type="h5"
              title="Source Sans Pro - Semi-bold - 600"
              align="left"
              color="dark"
              weight="bold"
            />
            <div className="p-styleguide__indent">
              <Title
                type="h3"
                title="ABCDEFGHIJKLMNOPQRSTUVWXYZ"
                align="left"
                color="dark"
                weight="semi-bold"
              />
              <Title
                type="h3"
                title="abcdefghijklmnopqrstuvwxyz"
                align="left"
                color="dark"
                weight="semi-bold"
              />
              <Title
                type="h3"
                title="1234567890"
                align="left"
                color="dark"
                weight="semi-bold"
              />
            </div>
          </div>

          <div className="p-styleguide p-styleguide__section--inner">
            <Title
              type="h5"
              title="Source Sans Pro - Bold - 700"
              align="left"
              color="dark"
              weight="bold"
            />
            <div className="p-styleguide__indent">
              <Title
                type="h3"
                title="ABCDEFGHIJKLMNOPQRSTUVWXYZ"
                align="left"
                color="dark"
                weight="bold"
              />
              <Title
                type="h3"
                title="abcdefghijklmnopqrstuvwxyz"
                align="left"
                color="dark"
                weight="bold"
              />
              <Title
                type="h3"
                title="1234567890"
                align="left"
                color="dark"
                weight="bold"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="p-styleguide p-styleguide__section">
        <Title
          type="h3"
          title="Font Sizes"
          align="left"
          color="dark"
          weight="bold"
        />
        <div className="p-styleguide p-styleguide__section--inner">
          <Title
            type="h5"
            title="Heading One - 40px - Bold &amp; Regular"
            align="left"
            color="dark"
            weight="bold"
          />
          <div className="p-styleguide__indent">
            <Title
              type="h1"
              title="This is an example heading."
              align="left"
              color="dark"
            />
            <Title
              type="h1"
              title="This is an example heading."
              align="left"
              color="dark"
              weight="regular"
            />
          </div>
        </div>

        <div className="p-styleguide p-styleguide__section--inner">
          <Title
            type="h5"
            title="Heading Two - 32px - Bold &amp; Regular"
            align="left"
            color="dark"
            weight="bold"
          />
          <div className="p-styleguide__indent">
            <Title
              type="h2"
              title="This is an example heading."
              align="left"
              color="dark"
            />
            <Title
              type="h2"
              title="This is an example heading."
              align="left"
              color="dark"
              weight="regular"
            />
          </div>
        </div>

        <div className="p-styleguide p-styleguide__section--inner">
          <Title
            type="h5"
            title="Heading Three - 32px - Bold &amp; Regular"
            align="left"
            color="dark"
            weight="bold"
          />
          <div className="p-styleguide__indent">
            <Title
              type="h3"
              title="This is an example heading."
              align="left"
              color="dark"
            />
            <Title
              type="h3"
              title="This is an example heading."
              align="left"
              color="dark"
              weight="regular"
            />
          </div>
        </div>

        <div className="p-styleguide p-styleguide__section--inner">
          <Title
            type="h5"
            title="Heading Four - 24px - Bold &amp; Regular"
            align="left"
            color="dark"
            weight="bold"
          />
          <div className="p-styleguide__indent">
            <Title
              type="h4"
              title="This is an example heading."
              align="left"
              color="dark"
            />
            <Title
              type="h4"
              title="This is an example heading."
              align="left"
              color="dark"
              weight="regular"
            />
          </div>
        </div>

        <div className="p-styleguide p-styleguide__section--inner">
          <Title
            type="h5"
            title="Heading Five - 18px - Bold"
            align="left"
            color="dark"
            weight="bold"
          />
          <div className="p-styleguide__indent">
            <Title
              type="h5"
              title="This is an example heading."
              align="left"
              color="dark"
            />
          </div>
        </div>

        <div className="p-styleguide p-styleguide__section--inner">
          <Title
            type="h5"
            title="Body Copy - 18px - Regular"
            align="left"
            color="dark"
            weight="bold"
          />
          <div className="p-styleguide__indent">
            <BodyCopy copy="This is an example of body copy." />
          </div>
        </div>

        <div className="p-styleguide p-styleguide__section--inner">
          <Title
            type="h5"
            title="Small - 16px - Regular"
            align="left"
            color="dark"
            weight="bold"
          />
          <div className="p-styleguide__indent">
            <BodyCopy copy="This is an example of body copy." small />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default StyleguidePage;
